import { useEffect, useRef } from 'react'

/* eslint-disable */
/**
 * React hook that listens to `beforeunload` window event.
 * @function
 * @param {?function(BeforeUnloadEvent): ?string} handler - Event listener
 *   called on `beforeunload` window event. It activates a confirmation dialog
 *   when `event.preventDefault()` is called or a string is returned.
 */
const useBeforeunload = (handler) => {
  const enabled = 'function' === typeof handler

  const handlerRef = useRef(handler)
  useEffect(() => {
    handlerRef.current = handler
  })

  useEffect(() => {
    if (enabled) {
      const listener = (event) => {
        const returnValue = handlerRef.current(event)

        if ('string' === typeof returnValue) {
          event.preventDefault()

          return (event.returnValue = returnValue)
        }

        if (event.defaultPrevented) {
          return (event.returnValue = '')
        }
      }

      window.addEventListener('beforeunload', listener)

      return () => {
        window.removeEventListener('beforeunload', listener)
      }
    }
  }, [enabled])
}

export default useBeforeunload;